import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import { FaArrowAltCircleRight} from "@react-icons/all-files/fa/FaArrowAltCircleRight";
import {Link} from 'gatsby'
import Star from "../../components/star";

const Powerpoint = () => {

    const powerpoints = [
        {
            title:"CSTA Newton's Laws Workshop, October 2008",
            presenter:'Dr. Lynn Cominsky & Dr. Kevin McLin',
            presentationType:'Teacher Workshop',
            page:'newtons2008PP'
        },
        {
            title:"Swift View of the Universe",
            presenter:'Dr. Lynn Cominsky',
            presentationType:'various locations',
            page:'swiftViewofTheUniverse'
        },
        {
            title:"The Road to the Internet",
            presenter:'Tom Arnold',
            presentationType:'SwEC GSFC',
            page:'roadToTheInternet'
        },
        {
            title:"Things my Mother Never Told me about the Universe",
            presenter:'Dr. Lynn Cominsky',
            presentationType:'CSTA Long Beach, CA',
            page:'mother'
        },
        {
            title:"Nature's Second Biggest Bangs: The Gamma Ray Burst Zoo",
            presenter:'Dr. Phil Plait',
            presentationType:'Workshop',
            page:'gammaRayBPP'
        },
        {
            title:"GEMS: The Invisible Universe October 2003- CSTA",
            presenter:'	Dr. Lynn Cominsky',
            presentationType:'Workshop',
            page:'gemsPP'
        },
        {  
            title:"GEMS: The Invisible Universe January 2003- AAPT",
            presenter:'Tim Graves and Sarah Silva',
            presentationType:'Workshop',
            page:'gems2003'
        },
        {
            title:"The Supernova, the Black Hole and the Gamma Ray Burst",
            presenter:'Dr. Phil Plait',
            presentationType:'To Educator Ambassadors',
            page:'supernova'
        },
        {
            title:"The Electromagnetic Spectrum",
            presenter:'Dr. Phil Plait',
            presentationType:'To Educator Ambassadors',
            page:'electromagneticSpectrum'
        },
        {
            title:"The Universe According to NASA",
            presenter:'Dr. Lynn Cominsky',
            presentationType:'To Educator Ambassadors',
            page:'universeNASA'
        },
        {
            title:"The Swift Gamma Ray Burst Explorer",
            presenter:'Pennsylvania State University',
            presentationType:'Teacher Workshop',
            page:'GRBExplorer'
        },
        {
            title:"CSTA Waves Light up the Universe!",
            presenter:'Dr. Laura Whitlock',
            presentationType:'Teacher Workshop',
            page:'CSTAWaves'
        },
        {
            title:"The Extreme Universe of Gamma-ray Astronomy",
            presenter:'Dr. Lynn Cominsky',
            presentationType:'Public Lecture',
            page:'ExtremeGamma'
        },
        {
            title:"Gamma-Ray Astronomy -The Short Story",
            presenter:'Dr. Lynn Cominsky',
            presentationType:'Public Lecture',
            page:'GRA'
        },
        {
            title:"Waves Light up the Universe",
            presenter:'Dr. Phil Plait',
            presentationType:'Teacher Workshop',
            page:'wavesLight'
        },
    ]

    return (
        <Layout>
            <div>
                <h1>SWIFT EDUCATION PRESENTATIONS</h1>
                <Star />
                <p>Swift scientists and educators have given many presentations about the Swift mission and its educational goals. These presentations are available here for viewing or download.</p>
                <div className='media-items-container'>
                    {powerpoints.map( (powerpoint,index) => 
                    <div key={index} className="item-container">
                        <h4>
                            <Link 
                                to={`/ppt/${powerpoint.page}`}
                                state={{ 
                                    slides:powerpoint.slides, 
                                    title:powerpoint.title, 
                                    presenter:powerpoint.presenter, 
                                    presentationType:'workshop',
                                    pptDownloads:{ 
                                      ppt:`/${powerpoint.folder}/${powerpoint.ppt}`,
                                      pptx:`/${powerpoint.folder}/${powerpoint.pptx}`
                                    },
                                }}
                            >
                                {powerpoint.title}
                            </Link>
                        </h4>
                        <p>{powerpoint.presenter}</p>
                        <p>{powerpoint.presentationType}</p>
                        <p className="media-item-buttons">
                                <Link 
                                    to={`/ppt/${powerpoint.page}`}
                                    state={{ 
                                        slides:powerpoint.slides, 
                                        title:powerpoint.title, 
                                        presenter:powerpoint.presenter, 
                                        presentationType:powerpoint.presentationType,
                                        pptDownloads:{ 
                                            ppt:`/${powerpoint.folder}/${powerpoint.ppt}`,
                                            pptx:`/${powerpoint.folder}/${powerpoint.pptx}`
                                        },
                                    }}
                                >
                                    Check it out! <FaArrowAltCircleRight/>
                                </Link>
                        </p>
                    </div>  
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default Powerpoint
